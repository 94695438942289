export const oidcSettings = {
    authority: process.env.VUE_APP_IDP_URL,
    client_id: 'bpi-mkbapp',
    redirect_uri: window.location.origin + '/oidc-callback',
    response_type: 'id_token token',
    scope: 'openid profile role bpi-mkbapi',
    post_logout_redirect_uri: window.location.origin + '/',    
    // refresh token in background
    automaticSilentRenew: true,
    loadUserInfo: true,
    // accessTokenExpiringNotificationTime: 30,
    revokeAccessTokenOnSignout: true,
    silent_redirect_uri: window.location.origin + '/oidc-silent-renew.html'
  }