/* eslint-disable no-console */
import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from './config/oidc'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profilePhoto: null
  },
  mutations: {
    setProfilePhoto (state, photo) {
      state.profilePhoto = photo
    }
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, {
        namespaced: false,
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => {
          console.log("OIDC user is loaded:", user)
          Vue.prototype.$http.defaults.headers.common['Authorization'] = `Bearer ${  
            user.access_token ? user.access_token : ""}`
        },
        userUnloaded: () => {
          console.log('OIDC user is unloaded')
          //window.location.reload()
        },
        accessTokenExpiring: () => {
          console.log('Access token will expire')
          //this.authenticateOidcSilent()
        },
        accessTokenExpired: () => {
          console.log('Access token did expire')
          //this.authenticateOidcSilent()
        },
        silentRenewError: () => {
          console.log('OIDC silent renew error')
          //window.location.reload()
        },
        userSignedOut: () => {
          console.log('OIDC user is signed out')
          //window.location.reload()
        }
      }
    )
  }
})