<!-- eslint-disable -->
<template>
  <v-app dark>       
    <v-navigation-drawer 
      v-if="oidcUser"
      v-model="drawer" 
      mobile-break-point="1023"
      class="secondary no-print"
      dark
      app>
      <v-toolbar flat color="primary">
        <v-list class="pa-1">
          <v-list-tile avatar>
            <v-list-tile-avatar>
              <img :src=profilePhoto v-if="profilePhoto" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-if="employee">{{ getEmployeeName }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-toolbar>      
      <v-list
        class="pt-0"
        dense>
        <v-divider light></v-divider>
        <v-list-tile 
          @click.stop="title = 'Menu'"
          :to="'/'">
          <v-list-tile-action>
            <v-icon>home</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Menu</v-list-tile-title>
        </v-list-tile>
        <v-list-tile 
          v-if="employee && employee.hasTimesheet"
          @click.stop="title = 'Timesheet'"
          :to="'/timesheet'">
          <v-list-tile-action>
            <v-icon>access_time</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Timesheet</v-list-tile-title>
        </v-list-tile>

        <v-divider light></v-divider>
        <v-list-group
          v-if="oidcUser && oidcUser.role.includes('Administrator')"
          no-action
          prepend-icon="settings"
        >
          <v-list-tile slot="activator">
            <v-list-tile-title>Beheer</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-for="item in admins" 
            :key="item.title"
            @click.stop="title = 'Beheer ' + item.title"
            :to="item.path == '#' ? '' : item.path"
            >
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
            <v-list-tile-action>
              <v-icon v-text="item.action"></v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-group
          v-if="oidcUser"
          no-action
          prepend-icon="bar_chart"
        >
          <v-list-tile slot="activator">
            <v-list-tile-title>Rapporten</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-for="item in reports" 
            :key="item.title"
            @click.stop="title = 'Rapport ' + item.title"
            :to="item.path == '#' ? '' : item.path"
            >
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
            <v-list-tile-action>
              <v-icon v-text="item.action"></v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-tile @click.stop="signOutOidc">
          <v-list-tile-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Uitloggen</v-list-tile-title>
        </v-list-tile>
        <v-divider light></v-divider>
        <v-list-tile to="/about">
          <v-list-tile-title class="text-xs-right font-weight-thin">versie 1.3</v-list-tile-title>
        </v-list-tile>       
      </v-list>
    </v-navigation-drawer>      
    <v-toolbar  
      v-if="oidcUser"
      fixed 
      app 
      color="primary"
      class="no-print"
      :clipped-left="clipped">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-toolbar>      
    <v-content>
      <v-container>
        <router-view  v-if="oidcUser || $route.meta.isOidcCallback" :employee="employee" :roles="roles"></router-view>
        <v-layout v-if="!oidcUser">
          <v-flex>
            <h1>Inloggen</h1>
            De sessie is verlopen, klik <v-btn small color="primary" @click="reloadWindow">hier</v-btn> om opnieuw in te loggen
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>
<style scoped>
  .v-list__tile--active { color: lime }
</style>
<style>
  @media screen {
    main.v-content {
      max-width:1280px;
    }
  }
  @media print {
    * { color: black }
    .application-wrap, .v-content__wrap { flex: 0 !important }
    .container { padding: 0 !important }
    .theme--dark.application, .theme--dark.v-table, .theme--dark.v-table thead th, .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row)  { background: white !important; color: black !important; }
    div[role=listitem] { border-bottom: 1px solid black !important; }
    div[role=listitem].primary {
      border-top: 2px solid black !important;
      background-color: black !important;
      font-weight: bold !important;
      color: white !important;
    }
    .no-print { display: none }
    table.v-table tbody td.success { color: white !important }
    table.v-table tbody td.grey { color: white !important }
    table.v-table tbody td.extra { border: 2px solid yellow !important }
    button { display: none !important }
    .v-content { padding: 0 !important }
    table.v-table { width: auto !important }
    table.v-table tbody td, table.v-table thead th { font-weight: bold !important }
    table.v-table tbody td:not(.extra), table.v-table thead th { border: 1px solid black !important }
    table.v-table tbody td:first-child, 
    table.v-table tbody td:not(:first-child), 
    table.v-table tbody th:first-child, 
    table.v-table tbody th:not(:first-child), 
    table.v-table thead td:first-child, 
    table.v-table thead td:not(:first-child), 
    table.v-table thead th:first-child, 
    table.v-table thead th:not(:first-child) { padding: 4px !important }
  }
</style>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  data () {
    return {
      title: 'BPI App',
      drawer: null,
      clipped: false,
      organization: null,
      employee: null,
      roles: null,
      admins: [
        { title: 'Organisatie', path: '/administration/organization', action: 'location_city' },
        { title: 'Medewerkers', path: '/administration/employees', action: 'people_outline' },
        { title: 'Producten', path: '/administration/products', action: 'extension' }
      ],
      reports: [
        { title: 'Medewerker', path: '/reports/employee', action: 'people_outline' },
        { title: 'Product', path: '/reports/product', action: 'extension' },
        { title: 'Project', path: '/reports/project', action: 'work' },
        { title: 'Klant', path: '/reports/client', action: 'recent_actors' }
      ],
      profilePhoto: null
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser',
      'oidcAccessTokenExp'
    ]),
    getEmployeeName () {
      return ((this.employee.firstname || '') + ' ' + (this.employee.surnamePrefix || '') + ' ' +  (this.employee.surname || '')).replace('  ', ' ')
    }
  },
  watch: {
    oidcUser(val) {
      if (val && val.role) this.roles = val.role
    }
  },
  methods: {
    ...mapActions([
      'signOutOidc',
      'authenticateOidcSilent'
    ]),
    reloadWindow() {
      window.location.reload()
    },
    setEmployee (data) {
      this.employee = data
    },
    setOrganization (data) {
      this.organization = data
    },
    setProfilePhoto (photo) {
      this.profilePhoto = photo
    },
    userLoaded () {
      this.$http.get('api/graph/profilephoto')
        .then(request =>  {
          this.setProfilePhoto('data:image/jpeg;base64, ' + request.data)
          this.$http.get('api/organization')
              .then(request => {
                this.setOrganization(request.data)
                this.$http.get('api/employee')
                    .then(request => this.setEmployee(request.data))
                    .catch(() => { console.error('employee error!') }) /* eslint-disable-line no-console */          
              })
              .catch((msg) => { console.error('organization error! ' + msg) }) /* eslint-disable-line no-console */          
        })
        .catch((e) => { console.error('profile photo error!' + e) }) /* eslint-disable-line no-console */
    }
  },
  mounted () {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
  },
  destroyed () {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
  }
}
</script>