import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import colors from 'vuetify/lib/util/colors'
import Touch from 'vuetify/es5/directives/touch'

Vue.use(Vuetify, {
  iconfont: 'md',  
  theme: {
    primary: colors.blueGrey,
    secondary: colors.grey.darken3,
    accent: colors.red,
    error: colors.red.accent2,
    info: colors.blue,
    success: colors.green.darken1,
    warning: colors.amber
  },
  directives: {
    Touch
  }
})
