import Vue from 'vue'
import Router from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/administration',
      name: 'Administration',
      component: () => import('./views/Administration.vue'),
      children: [
        {
          path: 'employees',
          name: 'AdministrationEmployees',
          component: () => import('./views/administration/Employees.vue')
        },
        {
          path: 'organization',
          name: 'AdministrationOrganization',
          component: () => import('./views/administration/Organization.vue')
        },
        {
          path: 'products',
          name: 'AdministrationProducts',
          component: () => import('./views/administration/Products.vue')
        }
      ],
      meta: {
        requiresAuth: true,
        role: ['Administrator']
      }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: () => import('./views/Reports.vue'),
      children: [
        {
          path: 'employee',
          name: 'ReportsEmployee',
          component: () => import('./views/reports/Employee.vue')
        },
        {
          path: 'project',
          name: 'ReportsProject',
          component: () => import('./views/reports/Project.vue'),
          meta: {
            requiresAuth: true,
            role: ['Administrator']
          }
        },
        {
          path: 'product',
          name: 'ReportsProduct',
          component: () => import('./views/reports/Product.vue'),
          meta: {
            requiresAuth: true,
            role: ['Administrator']
          }
        },
        {
          path: 'client',
          name: 'ReportsClient',
          component: () => import('./views/reports/Client.vue'),
          meta: {
            requiresAuth: true,
            role: ['Administrator']
          }
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/timesheet',
      name: 'Timesheet',
      component: () => import('./views/Timesheet.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./views/About.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/accessdenied',
      name: 'AccessDenied',
      component: () => import('./views/AccessDenied.vue'),
    },
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: () => import('./views/OidcCallback.vue'),
      meta: {
        isOidcCallback: true,
        isPublic: true
      }
    }
  ]
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store))

export default router;