import 'babel-polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store'

Vue.config.productionTip = false

// Setup axios as the Vue default $http library
Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL // same as the Url the server listens to

const moment = require('moment')
require('moment/locale/nl')

Vue.use(require('vue-moment'), {
    moment
})

console.log(Vue.moment().locale()) /* eslint-disable-line no-console */

new Vue({
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')